import { toUnicode } from 'punycode'
import * as linkify from 'linkifyjs'

export const encodeEmail = (email: string) => {
  const [localPart, domain] = email.split('@')
  const encodedDomain = toUnicode(domain)
  return `${localPart}@${encodedDomain}`
}

export function isValidEmail(text: string) {
  try {
    const encodedText = encodeEmail(text)
    return linkify.test(encodedText, 'email')
  } catch (e) {
    console.log(e)
    return false
  }
}
